<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo2_square.png"
            height="90px"
            width="180px"
            fluid
            alt="Logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido a Toc Toc Ticket!
        </b-card-title>
        <b-card-text class="mb-2">
          Porfavor ingresa a tu cuenta y empieza la aventura
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-row class="justify-content-center mb-2">
              <b-col
                lg="3"
                class="order-lg-2"
              >
                <base-cropper
                  :model="user"
                  :boundary-size="{ width: 320, height: 320 }"
                  :viewport-size="{ width: 300, height: 300 }"
                  @croppedImage="user.logo = $event"
                />
              </b-col>
            </b-row>

            <!-- username -->
            <b-form-group
              label="Nombre"
              label-for="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="user.name"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="johndoe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Correo eléctronico"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <!-- <b-form-group
              label="Contraseña"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Registrar
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>¿Ya tienes cuenta? </span>
          <b-link :to="{name:'login'}">
            <span>Inicia sesión</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            ó
          </div>
        </div>

        <!-- social buttons -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            href="javascript:void(0)"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="google"
            href="javascript:void(0)"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
        </div>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BImg, BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseCropper from '@core/components/BaseCropper.vue'

export default {
  components: {
    // BSV
    BaseCropper,
    BRow,
    BCol,
    BImg,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        email: '',
        name: '',
        role_name: 'customer',
      },
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    // passwordToggleIcon() {
    //   return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },
  },
  methods: {
    ...mapActions('users', ['addUser']),
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.addUser(this.user)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Usuario registrado',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: 'Recibirás un correo en breve para configurar tu contraseña',
                },
              })
            })
            .then(() => {
              this.$router.push({ name: 'auth-login' })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
